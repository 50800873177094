<!--提报新工单-->
<template>
  <div>
    <el-form ref="createform" :model="workorder" :rules="createRules" label-width="80px" size="mini">
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('受理人')" :placeholder="msg('请选择')" prop="handlerId">
            <el-col :span="12">
              <el-select v-model="workorder.handlerId" :placeholder="msg('请选择')" ref="handlerSelect" @click.native="loadWorkOrderCountList">
                <el-option v-for="d in servicelist" :key="d.id" :label="d.workOrderCount==null?d.fullname:(d.fullname+'(等待处理工单:'+d.workOrderCount+')')" :value="d.id"></el-option>
              </el-select>
              <el-link type="primary" icon="el-icon-circle-plus" @click="onHandlerSelect"></el-link>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
<!--      <el-row>-->
<!--        <el-col :xs="24" :sm="24">-->
<!--          <el-form-item :label="msg('通知人')" :placeholder="msg('请选择')" prop="notifierId">-->
<!--            <el-col :span="12">-->
<!--              <el-select v-model="workorder.notifierId" clearable :placeholder="msg('请选择')">-->
<!--                <el-option v-for="d in servicelist" :key="d.id" :label="d.fullname" :value="d.id"></el-option>-->
<!--              </el-select>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--      </el-row>-->
      <el-row>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('工单类型')" prop="typeName">
            <el-select v-model="workorder.typeName" size="mini" clearable :placeholder="msg('请选择')">
              <el-option v-for="d in typelist" :key="d" :label="d" :value="d"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('工单细分')" prop="typeDesc">
            <el-input type="input" v-model="workorder.typeDesc"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('紧急程度')" prop="level">
            <el-select v-model="workorder.level" :placeholder="msg('请选择')">
              <el-option v-for="d in getDicts('workOrderLevel')"
                         :key="d.value" :label="d.label" :value="d.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('工单主题')" :placeholder="msg('请输入')" prop="title">
            <el-input v-model="workorder.title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('问题描述')" :placeholder="msg('请输入')" prop="description">
            <el-input type="textarea" v-model="workorder.description"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div style="text-align:center;">
        <el-button type="primary" size="mini" @click="submit" :fullscreen="isMinScreen"><i
            class="el-icon-check"></i><span>{{ msg('提交') }}</span></el-button>
        <el-button size="mini" v-on:click="$emit('close-dg')">{{ msg('取消') }}</el-button>
      </div>

    </el-form>
    <el-dialog :title="msg('选择用户')" :visible.sync="userSelectVisible" :fullscreen="isMinScreen"
               :close-on-click-modal="false" append-to-body>
      <user-select v-on:close-dg="closeUserSelectDialog" ref="UserSelect"/>
    </el-dialog>
  </div>
</template>

<script>

import UserSelect from "./UserSelect.vue";
export default {
    name: 'WorkOrderAdd',
    mounted() {
      this.loadservicelist();
      this.loadtypelist();
    },
    components:{
      UserSelect: UserSelect
    },
    data(){
        return{
          userSelectVisible: false,
          lastLoadTime: 0,
          servicelist: [],
          typelist: [],
          createRules: {
            typeName: [
              {required: true, message: this.msg("请选择工单类型"), trigger: 'change'},
            ],
            level: [
              {required: true, message: this.msg("请选择紧急程度"), trigger: 'change'},
            ],
            // handlerId: [
            //   {required: true, message: this.msg("请选择受理人"), trigger: 'blur'},
            // ],
            title: [
              {required: true, message: this.msg("工单主题不能为空"), trigger: 'blur'},
            ],
            description: [
              {required: true, message: this.msg("问题描述不能为空"), trigger: 'blur'},
            ],
          },
        }
    },
    props: {
      workorder: Object,
    },
    methods:{
        submit(){
          this.$refs['createform'].validate((valid) => {
            if (valid) {
              this.onSubmit();
            } else {
              return false;
            }
          });
        },
        onSubmit(){
            if(!this.workorder.handlerId){
                this.$message(this.msg('请选择受理人'));
                return;
            }
            this.workorder.handlerName = this.servicelist.find(a => a.id == this.workorder.handlerId).fullname;
            var url = this.$kit.api.workorder.create;
            var _this = this;
            this.$axios.post(url, _this.workorder)
                .then(function(result){
                    if (result.status) {
                      _this.lastLoadTime = 0;
                      _this.$message.success(_this.msg("提交工单成功"));
                      if (result.status) {
                        _this.$emit("close-dg", true);
                      }
                    } else {
                      _this.$message.error(_this.msg(result.msg ? "创建工单失败：" + result.msg : "创建工单失败"));
                    }
                });
        },
        onHandlerSelect(){
          this.userSelectVisible=true;
        },
        loadservicelist(){
          var _this=this;
          var url = this.$kit.api.workorder.servicelist;
          this.$axios.post(url)
              .then(function(result){
                if (result.status) {
                  if (result.data) {
                    _this.servicelist = result.data;
                  }
                } else {
                  _this.$message.error(result.msg || _this.msg("创建失败"));
                }
              });
          this.lastLoadTime = new Date().getTime();
        },
        loadtypelist(){
          var _this = this;
          var url = this.$kit.api.workorder.typelist;
          this.$axios.post(url)
              .then(function (result) {
                if (result.status) {
                  if (result.data) {
                    _this.typelist = result.data;
                  }
                } else {
                  _this.$message.error(result.msg || _this.msg("创建失败"));
                }
              });
        },
        loadWorkOrderCountList(){
          console.log("workorder count refresh")
          var _this = this;
          if (this.servicelist == null || this.servicelist.length <= 0) {
            return;
          }
          var now = new Date().getTime();
          if (now - this.lastLoadTime < 30000) {
            return;
          }
          var uids = this.servicelist.map(a => a.id);
          var data = {};
          data.userIds = uids.join();
          var url = this.$kit.api.workorder.workOrderCountList;
          this.$axios.post(url, data).then(function (result) {
            if (result.status) {
              var list = _this.servicelist;
              for(var i=0;i<list.length;i++){
                var u = list[i];
                var num = result.data[u.id];
                if (num != null && typeof (num) != 'undefined') {
                  u.workOrderCount = num;
                  _this.$set(_this.servicelist, i, u);
                }
              }
            } else {
              _this.$message.error(result.msg || _this.msg("创建失败"));
            }
          });
          this.lastLoadTime = new Date().getTime();
        },
        closeUserSelectDialog(user) {
          var _this = this;
          _this.userSelectVisible = false;
          if (!_this.servicelist.find(a => a.id == user.id)) {
            _this.servicelist.push(user);

            var url = _this.$kit.api.workorder.workOrderCount + "?userId=" + user.id;
            _this.$axios.get(url)
                .then(function (result) {
                  let userIndex = _this.servicelist.indexOf(user);
                  user.workOrderCount = (result.status ? result.data : 0);
                  _this.$set(_this.servicelist, userIndex, user);
                })
                .catch(function (error) { // 请求失败处理
                  console.log(error);
                });
          }
          _this.workorder.handlerId = user.id;
        }
    },
    computed: {
      isMinScreen: {  //是否小屏幕，浏览器
        get() {
          var is = this.$store.state.isMinScreen;
          return is;
        }
      }
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
