<!-- 用户选择-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>
                        </div>                
                    </el-link>
                </div>
            </template>
        </ctrl-box>
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('用户名')">
                        <el-input v-model="scope.query.fullname" clearable></el-input>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>
        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight"
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="datas"
                :columns="columns"
                @cell-click="checkRow"
                @current-change="rowSelect"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>
        <div style="text-align: center">
          <el-button type="primary" size="mini" @click="doSelect" :fullscreen="isMinScreen"><i
              class="el-icon-check"></i><span>{{ msg('确定') }}</span></el-button>
        </div>
    </div>
</template>

<script>
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
export default {
    name: 'UserSelect',
    components: {
        CtrlBox: CtrlBox,
        Pagenator:Pagenator,
        QueryBox: QueryBox,
    },
    mounted(){
        this.getDefaultView(()=>{
            this.list();
        });
        this.getDepts();
    },
    data(){
        return {
            query:{
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
                queries: [],
                fullname:null,
            },
            showQuery: false,
           
            columns: [],
            viewSetting: null,
            originCols: null,

            datas: [],      //展示列表
           
            curr: null,
            currId: null,
            curRow: null,

            deptTree: [],
            deptTreeExpands: [],
            depts: [],
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        //获取默认视图
        getDefaultView(cb){

            this.$axios.get(this.$kit.api.viewSetting.defaultView+"?t="+this.dicts.views.userView)
                .then((result) => {
                    if(result.status){
                        this.buildColumns(result.data.details);
                        this.buildQueries(result.data.queries);
                        this.viewSetting = result.data;

                        if(cb) cb();

                    }
                });
        },
        //初始化表格
        buildColumns(details){
            this.columns = [
              {
                field: "id", column: "id", title: this.msg("ID"), minWidth: 80, sortable: true,
                filters: [{data: null}],
                filterRender: {name: 'FilterInput'},
              },
              {
                field: "fullname", column: "fullname", title: this.msg("用户名称"), minWidth: 200, sortable: true,
                filters: [{data: null}],
                filterRender: {name: 'FilterInput'},
              },
            //   {
            //     field: "username", column: "username", title: this.msg("用户账号"), minWidth: 100, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //   },
            //   {
            //     field: "saleCode", column: "sale_code", title: this.msg("性别"), minWidth: 80, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //     slots: {
            //       default: ({ row }) => {
            //         let gender = this.dicts.genders.find(d => d.value === row['saleCode']);
            //         return [
            //           <el-tag type={gender?gender.type:''}>{gender?gender.label:row['saleCode']}</el-tag>
            //         ];
            //       },
            //     }
            //   },
            //   {
            //     field: "deptLabel", column: "dept_label", title: this.msg("所在组织"), minWidth: 80, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //   },
            //   {
            //     field: "deptLabelPath", column: "dept_label_path", title: this.msg("组织结构"), minWidth: 200, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //   },
            //   {
            //     field: "userTag", column: "user_tag", title: this.msg("会员类型"), minWidth: 80, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //   },
            //   {
            //     field: "status", column: "status", title: this.msg("账号状态"), minWidth: 80, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //     slots: {
            //       default: ({ row }) => {
            //         let status = this.dicts.userStatuses.find(d => d.value === row.status);
            //         return [
            //           <el-tag type={status?status.type:''}>{status?status.label:row.status}</el-tag>
            //         ];
            //       },
            //     }
            //   },
            //   {
            //     field: "vip", column: "vip", title: this.msg("vip"), minWidth: 80, sortable: true,
            //     filters: [{data: null}],
            //     filterRender: {name: 'FilterInput'},
            //     slots: {
            //       default: ({row}) => {
            //         let vip = this.dicts.vipTypes.find(d => d.value === row['vip']);
            //         return [
            //           <el-tag type={vip ? vip.type : ''}>{vip ? vip.label : row['vip']}</el-tag>
            //         ];
            //       },
            //     }
            //   },
            ];

            this.originCols = this.columns.map(e =>  {
                return {
                    tableName : 'sys_user',
                    colName: e.column,
                    fieldName: e.field,
                    hasAlias: '0',
                    tableAlias: 't'
                }
            });
        },
        //初始化查询条件
        buildQueries(qs){

            qs.forEach(q => {
                if(q.dvalue || q.dvalue === 0){

                    if(q.type == 3){ //3-表示是时间类型
                        let curr = new Date();
                        let prevTime =  curr.getTime() - (q.dvalue * 24 * 3600 * 1000);
                        let prev = new Date(prevTime);
                        q['dvalues'] = [this.$kit.fmtDate(prev), this.$kit.fmtDate(curr)]
                    }else if(q.type == 4){//日期时间类型
                        let curr = new Date();
                        let prevTime =  curr.getTime() - (q.dvalue * 24 * 3600 * 1000);
                        let prev = new Date(prevTime);
                        q['dvalues'] = [this.$kit.fmtDateTime(prev), this.$kit.fmtDateTime(curr)]
                    }else if(q.op == 6 || q.op == 8){ //表示可以有多个值
                        q['dvalues'] = q.dvalue.split(",").map(v => (v*1 || v));
                    }else{
                        let val = q.dvalue;
                        let val1 = val*1;
                        q.dvalue = val1 || val;
                        q['dvalues'] = []; 
                    }
                }else{
                    q['dvalues'] = [];
                }
            });

            this.query.queries = qs;
            return qs;
        },
        //获取列表数据
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }
            var params = this.$kit.rebuildQuery(this.query);
            params['columns'] = this.originCols;
            //获取查询的数，并且过滤掉空数据，
            let queries;
            if(!this.query.fullname){
                queries = [];
            }else{
                queries = [{
                        dvalue: this.query.fullname,
                        tableName: 'sys_user',
                        colName: 'fullname',
                        op: 5,
                        type: 0,
                        tableAlias: 't'
                    }]; 
            }
            this.query.queries.forEach(e => {
                    let values = e.dvalues;
                    let value = e.dvalue;

                    let q = {
                        dvalue: e.dvalue,
                        tableName: e.tableName,
                        colName: e.colName,
                        op: e.op,
                        type: e.type,
                        tableAlias: e.tableAlias
                    }
                    if(q.type == 0 && q.op == 6){
                        if(value){
                            value = value.split(/[\s,]+/);
                            q.dvalue = value.join(",");
                            queries.push(q);
                        }
                    }else{
                        if(q.type == 3 || q.op == 6 || q.op==8 || q.type == 4){
                            if(values && values.length>0){
                                q.dvalue = values.join(",");
                                queries.push(q);
                            }
                        }else if(q.dvalue || q.dvalue === 0){
                            queries.push(q);
                        }
                    }
                });
            params['queries'] = queries;
            this.$axios.post(this.$kit.api.user.list, params)
                .then((result) => {
                    if(result.status){
                        this.datas = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        //重置查询条件
        reset(){
            this.$kit.clearQuery(this.query);
            if(this.query.queries){ 
                // this.query.queries.forEach(e => {e.dvalue = null; e.dvalues = []});
                this.query.queries.forEach(e => {
                        if(e.disabled == 0){
                            if(e instanceof Array){
                                e.dvalues = [];
                            }else{
                                e.dvalues = null;
                            }
                            if(e.hasOwnProperty('dvalue')){
                                e.dvalue = null; 
                            }
                        }
                    }
                );
            }
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        rowSelect(currentRow, oldCurrentRow) {
          this.curRow = currentRow.row;
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        //获取当前登录用的组织树
        getDepts(){
            this.$axios.post(this.$kit.api.dept.listByDataPathGrant, this.query)
                .then((result) => {
                    if(result.status){
                        var data = result.data;
                        this.depts = data;
                        this.buildTree(data);
                        this.setExpandDepts(this.deptTree);
                    }
                });
        },
        setExpandDepts(depts){
            depts.forEach(e => {
                if(e.children && e.children.length<=10){
                    this.deptTreeExpands.push(e.id);
                    this.setExpandDepts(e.children);
                }
            });
        },

        buildTree(depts){

            var map = {};
            depts.forEach(m => map[m.id] = m);
            
            for(let key in map){
                var m = map[key];
                if(m.parentId){
                    var parent = map[m.parentId];
                    if(parent){
                        if(!parent['children']){
                            parent['children'] = [];
                        }

                        parent.children.push(m);
                        m.parent = parent;
                    }
                }
            }

            var ms = [];
            for(let key in map){
                let m = map[key];
                if(m.parent == null){
                    ms.push(m);
                }
            }
            this.deptTree = ms;
        },
        doSelect(){
          if (this.curRow == null) {
            this.$message(this.msg('请选择受理人'));
            return;
          }
          var user = {};
          user.id = this.curRow.id;
          user.fullname = this.curRow.fullname;
          this.$emit("close-dg", user);
        }
    }
    
}
</script>

<style scoped lang="scss">
.view-group h5{margin-top:8px; margin-bottom:8px; border-bottom: 1px solid #dcdfe6;}
.view-group >>> .el-link{display:inline-block; padding: 5px 15px;}
</style>
